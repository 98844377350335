import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 's9-video',
  styleUrls: ['./video.component.scss'],
  template: `
    <div class="video">
      <div class="title">{{ title }}</div>
      <video width="{{ width }}" height="{{ height }}" controls>
        <source src="{{ source }}" type="{{ type }}" />
        {{ notSupportedMessage }}
      </video>
    </div>
  `,
})
export class VideoComponent {
  @Input() width = 320;
  @Input() height = 240;
  @Input() type = 'video/mp4';
  @Input() source: string | undefined;
  @Input() title: string | undefined;
  @Input() notSupportedMessage = 'Your browser does not support the video tag.';
}
