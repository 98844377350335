import { Component, OnInit } from '@angular/core';

@Component({
  selector: 's9-main',
  template: `
    <s9-navigation></s9-navigation>
    <div class="custom-container container pt-4 mrg-horizon-auto">
      <router-outlet></router-outlet>
    </div>
    <s9-footer></s9-footer>
  `,
})
export class MainLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
