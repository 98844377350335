import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { PageNotFoundPageComponent } from './pages/page-not-found-page/page-not-found-page.component';
import { AuthGuard } from './shared/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./features/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'sacrament-program',
        loadChildren: () =>
          import('./features/sacrament-program/sacrament-program.module').then(
            (m) => m.SacramentProgramModule
          ),
      },
      {
        path: 'cheat-sheet',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./features/cheat-sheet/cheat-sheet.module').then(
            (m) => m.CheatSheetModule
          ),
      },
      {
        path: 'trainings',
        loadChildren: () =>
          import('./features/trainings/trainings.module').then(
            (m) => m.TrainingsModule
          ),
      },
      {
        path: 'announcements',
        loadChildren: () =>
          import('./features/announcements/announcements.module').then(
            (m) => m.AnnouncementsModule
          ),
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
