import { Component } from '@angular/core';

@Component({
  selector: 's9-footer',
  styles: [
    '#footer {position: relative; min-height: 200px; margin-top: 80px; margin-left: auto;}',
    'img.footer-logo {opacity: 0.09; position: relative; margin: 0 auto -180px 0;}',
  ],
  template: `
    <div class="container custom-container mrg-btm-150 text-center" id="footer">
      <img src="./assets/logo.png" class="footer-logo" />
      <span style="vertical-align:middle; display: inline-block;">
        <br /><small _ngcontent-gql-c26="" class="text-muted"
          ><b>Disclaimer:</b> This is not an official, Church-sponsored website
          or product. Contents are not sponsored or endorsed by or officially
          represent The Church of Jesus Christ of Latter-day Saints.</small
        ></span
      >
      <br />
      <small _ngcontent-gql-c26="" class="text-muted"
        >Created by: Josh Tune / Managed by: Alipate Prescott</small
      >
      <br />
      &#169; 2023 | Saratoga Springs 9 Ward Bishopric
    </div>
  `,
})
export class FooterComponent {}
