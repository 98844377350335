import { Component, Input } from '@angular/core';
import { Person } from '../../models/person';

@Component({
  selector: 's9-person',
  template: `
    {{ person.name }}<br *ngIf="person.details" />
    <small *ngIf="person.details">{{ person.details }}</small>
  `,
})
export class PersonComponent {
  @Input() person: Person;
}
