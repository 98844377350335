import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundPageComponent } from './page-not-found-page/page-not-found-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [PageNotFoundPageComponent, LoginPageComponent],
  imports: [CommonModule, SharedModule],
  exports: [PageNotFoundPageComponent, LoginPageComponent],
})
export class PagesModule {}
