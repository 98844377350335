import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Title } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { VideoComponent } from './components/video/video.component';
import { HymnComponent } from './components/hymn/hymn.component';
import { PersonComponent } from './components/person/person.component';
import { UtahTimeComponent } from './components/utah-time/utah-time.component';

@NgModule({
  declarations: [
    HymnComponent,
    PersonComponent,
    UtahTimeComponent,
    VideoComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  exports: [
    CommonModule,
    NgbModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,

    HymnComponent,
    PersonComponent,
    UtahTimeComponent,
    VideoComponent,
  ],
  providers: [CookieService, Title],
})
export class SharedModule {}
