import { Component, Input } from '@angular/core';
import { Hymn } from '../../models/hymn';

@Component({
  selector: 's9-hymn',
  template: `
    <span>{{ hymn.title }}</span
    ><br />
    <small>
      <em>{{ hymn.language }} Hymn No.</em> {{ hymn.page }}
    </small>
    <a
      [href]="hymn.link"
      target="_blank"
      class="btn btn-sm btn-secondary font-size-10 text-capitalize"
    >
      LYRICS
    </a>
  `,
})
export class HymnComponent {
  @Input() hymn: Hymn;
}
