import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 's9-login-page',
  styles: [
    'form { max-width: 300px; margin: 0 auto;}',
    '.logo { height: 50px; width: 50px; }',
  ],
  template: `
    <div class="custom-container pt-4 mrg-horizon-auto">
      <form [formGroup]="loginForm" *ngIf="loginForm">
        <section class="mt-4 mb-4 text-center">
          <img src="./assets/logo.png" class="logo" />
          <span class="ml-2">Saratoga 9</span>
        </section>

        <div class="form-group">
          <input
            type="text"
            formControlName="username"
            class="form-control"
            id="user-username"
            aria-xdescribedby="usernameHelp"
            placeholder="Username"
          />
        </div>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            id="user-password"
            formControlName="password"
            placeholder="Password"
          />
        </div>
        <div class="form-group text-right">
          <button (click)="login()" class="btn btn-secondary">Login</button>
        </div>
      </form>
    </div>
  `,
})
export class LoginPageComponent implements OnInit {
  loginForm: FormGroup | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  login(): void {
    if (this.loginForm) {
      this.authService.login(
        this.loginForm.value.username,
        this.loginForm.value.password
      );
      if (this.authService.isLoggedIn()) {
        this.router.navigateByUrl('/home');
      } else {
        this.router.navigateByUrl('/login');
      }
    }
  }
}
