import { Component, OnInit } from '@angular/core';

@Component({
  selector: 's9-page-not-found-page',
  styleUrls: ['./page-not-found-page.component.scss'],
  template: ` Page Not Found Page `,
})
export class PageNotFoundPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
