import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private cookieService: CookieService) {}

  login(username: string, password: string): boolean {
    const authenticated = username === 'admin' && password === 'saratoga9';
    this.cookieService.set('authenticated', authenticated ? 'true' : 'false');
    return authenticated;
  }

  isLoggedIn(): boolean {
    return this.cookieService.get('authenticated') === 'true';
  }

  logout(): void {
    this.cookieService.delete('authenticated');
  }
}
