import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { FooterComponent } from './footer/footer.component';
import { NavigationComponent } from './navigation/navigation.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [FooterComponent, MainLayoutComponent, NavigationComponent],
  exports: [FooterComponent, MainLayoutComponent, NavigationComponent],
  imports: [CommonModule, RouterModule],
})
export class LayoutsModule {}
