import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';

export interface NavigationLink {
  protected: boolean;
  active: boolean;
  routerLink?: string;
  link?: string;
  label: string;
}

@Component({
  selector: 's9-navigation',
  styles: [
    '.logo { height: 50px; width: 50px;}',
    '.nav-item { cursor: pointer; }',
  ],
  template: `
    <nav class="navbar navbar-expand-lg navbar-light mt-3">
      <div class="container custom-container">
        <a
          class="navbar-brand d-flex align-items-center"
          (click)="setDocTitle('Saratoga 9 | Home')"
          routerLink="/home"
        >
          <img src="./assets/logo.png" class="logo" />
          <span class="ml-2">Saratoga Hiva</span>
        </a>
        <div class="btn-group">
          <button
            class="btn btn-info dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            data-target="#navbar-items"
            aria-controls="navbar-items"
            aria-expanded="false"
            id="Toggle navigation"
          >
            Menu
          </button>
          <ul
            class="dropdown-menu dropdown-menu-dark"
            aria-labelledby="Toggle navigation"
          >
            <li><a class="dropdown-item" routerLink="home">Home</a></li>
            <li>
              <a class="dropdown-item" routerLink="announcements"
                >Announcements/Calendar/Lessons</a
              >
            </li>
            <!-- <li>
              <a class="dropdown-item" routerLink="trainings"
                >Sunday School Zoom Training</a
              >
            </li> -->
            <li>
              <hr class="dropdown-divider" />
            </li>
            <li class="nav-item" *ngFor="let link of links$ | async">
              <a
                class="nav-item nav-link"
                *ngIf="link.routerLink && link.routerLink.length"
                routerLink="{{ link.routerLink }}"
              >
                {{ link.label }}
              </a>
              <a
                class="nav-item nav-link"
                *ngIf="link.link && link.link.length"
                [innerHtml]="link.label"
                target="_blank"
                href="{{ link.link }}"
              >
                {{ link.label }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-item nav-link"
                (click)="setDocTitle('Saratoga 9 | Home')"
                routerLink="/login"
                *ngIf="!authenticated"
              >
                <i class="fas fa-sign-in-alt"></i> Sign In
              </a>
              <a
                class="nav-item nav-link"
                *ngIf="authenticated"
                (click)="logout()"
              >
                <i class="fas fa-sign-out-alt"></i> Sign Out
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  `,
})
export class NavigationComponent implements OnInit {
  linksList: NavigationLink[] = [
    {
      protected: true,
      active: true,
      routerLink: '/cheat-sheet',
      label: 'Cheat Sheet',
    },
    // {
    //   protected: false,
    //   active: true,
    //   link: 'https://forms.gle/1RAdoBhQoZGZ6GQF7',
    //   label: '<i class="fas fa-comments"></i>',
    // },
  ];

  links$: BehaviorSubject<NavigationLink[]> = new BehaviorSubject<
    NavigationLink[]
  >(this.linksList.filter((l) => l.active && !l.protected));
  authenticated: boolean | undefined;

  constructor(
    private authService: AuthService,
    private router: Router,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.authenticated = this.authService.isLoggedIn();
    if (this.authenticated) {
      const links = this.linksList.filter((l) => l.active);
      this.links$.next(links);
    }
  }

  logout(): void {
    this.authService.logout();
    this.router.navigateByUrl('/home');
    this.authenticated = false;
    this.links$.next(this.linksList.filter((l) => l.active && !l.protected));
  }

  setDocTitle(title: string): void {
    this.titleService.setTitle(title);
  }
}
