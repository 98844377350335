import { Component, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
import { Dayjs } from 'dayjs';

@Component({
  selector: 's9-utah-time',
  template: `
    <div
      *ngIf="time?.length"
      class="font-size-15 d-flex align-items-center text-muted"
    >
      UTAH TIME {{ time }}
    </div>
  `,
})
export class UtahTimeComponent implements OnInit {
  date: string;
  time: string;

  ngOnInit(): void {
    this.updateTime();
  }

  utahDate = (): Dayjs => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const utahTimeZone = 'America/Denver';
    return dayjs.tz(new Date(), utahTimeZone);
  };

  utahTimeStr = (): string => {
    return this.utahDate().format('hh:mm:ss A');
  };

  utahDateStr = (): string => {
    return this.utahDate().format('YYYY-MM-DD');
  };

  updateTime(): void {
    setTimeout(() => {
      this.time = this.utahTimeStr();
      this.date = this.utahDateStr();
      this.updateTime();
    }, 1000);
  }
}
